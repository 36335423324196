/** @format */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}
